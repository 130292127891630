.sixty-percent-width {
  width: 60% !important;
}
.forty-percent-width {
  width: 40% !important;
}

@media print and (color) {
  * {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}

.printable.no-animation {
  /*border: solid 1px blue;*/
  max-width: 1066px;
}

.printable > div.print-only {
  display: none;
}

@media print {

  div.ui.popup {
    display: none !important;
  }

  /* overwitting 'display: flex' with 'display: block' for Grid elements */
  div.ui.padded.grid {
    display: block !important;
  }

  div#crud-show-toolbar {
    display: none;
  }

  .printable > div {
    /*border: solid 1px red;*/
    page-break-inside: avoid;
  }

  .printable > div.print-only {
    display: block !important;
  }

  .printable h3.ui.dividing.header {
    /*border: solid 1px orange;
    margin-bottom: 0px !important;*/
  }

  .printable table.ui.celled.table {
    /*border: solid 1px green;
    margin-top: 0px !important;*/
    page-break-inside: avoid !important;
  }

  .printable .recharts-responsive-container {
    /*border: solid 1px green;*/
    page-break-inside: avoid;
  }

  .printable .recharts-responsive-container > .recharts-wrapper {
    margin: 0 auto; /* centralizes chart inside div */
    width: 100% !important; /* makes sure it fills parent div */
  }

  .printable .recharts-responsive-container > .recharts-wrapper > svg {
    width: 100% !important; /* scales SVG to fit inside wrapper */
    display: block !important;
    page-break-inside: avoid !important;
  }

  /* centralize legends */
  .printable .recharts-responsive-container > .recharts-wrapper > .recharts-legend-wrapper {
    width: 100% !important;
    left: 0;
    right: 0;
  }

  .printable .recharts-responsive-container > .recharts-wrapper > div.recharts-legend-wrapper > ul.recharts-default-legend:last-child {
    margin-right: 0px !important; /* remove margin form last legend item */
  }

  .printable table.ui.celled.table > thead,
  .printable table.ui.celled.table > tfoot {
    display: table-row-group
  }

  .column-indicador {
    padding-left: 0 !important;
  }

  .column-indicador:nth-child(5n) {
    padding-right: 0 !important;
  }

  .recadosGestor .column:first-child,
  .shrink .column:first-child {
    padding-left: 0 !important;
  }

  .printableHeader .column:last-child,
  .recadosGestor .column:last-child,
  .shrink .column:last-child {
    padding-right: 0 !important;
  }

  .avoid-page-break {
    page-break-inside: avoid !important;
  }

  .sixty-percent-width {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .forty-percent-width {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .ui.horizontal.divider {
    page-break-after: avoid;
  }

  .markdown-container {
    page-break-inside: avoid;
  }
}
